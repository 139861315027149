import React from 'react';

const IconSpotify = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <title>Spotify</title>
    <path data-name="layer2"
      d="M32 2a30 30 0 1 0 30 30A29.968 29.968 0 0 0 32 2z" fill="none" stroke="#ffffff"
      stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round"></path>
      <path data-name="layer1" d="M43.531 46.25a2.488 2.488 0 0 1-1.313-.469 30.038 30.038 0 0 0-15.562-4.031 45.077 45.077 0 0 0-9.469 1.031 10.354 10.354 0 0 1-1.5.281 1.879 1.879 0 0 1-1.875-1.875 1.978 1.978 0 0 1 1.594-2.063 50.53 50.53 0 0 1 11.25-1.313 33.55 33.55 0 0 1 17.437 4.5 2 2 0 0 1 1.219 1.969 1.73 1.73 0 0 1-1.781 1.97zm3.281-7.969a2.657 2.657 0 0 1-1.5-.562 38.834 38.834 0 0 0-19.031-4.781 40.6 40.6 0 0 0-9.75 1.219 4.413 4.413 0 0 1-1.5.375 2.321 2.321 0 0 1-2.344-2.344 2.44 2.44 0 0 1 1.875-2.531 41.027 41.027 0 0 1 11.813-1.687A41.912 41.912 0 0 1 47.75 33.5a2.421 2.421 0 0 1 1.406 2.344 2.344 2.344 0 0 1-2.344 2.437zm3.75-9.187a2.833 2.833 0 0 1-1.5-.469c-5.531-3.281-13.969-5.062-22.031-5.062a46.712 46.712 0 0 0-12 1.406 8.442 8.442 0 0 1-1.5.281 2.842 2.842 0 0 1-2.812-2.906 3.012 3.012 0 0 1 2.062-2.906 52.412 52.412 0 0 1 14.25-1.875c8.812 0 18.094 1.781 24.844 5.813a2.836 2.836 0 0 1 1.5 2.719 2.867 2.867 0 0 1-2.813 2.999z"
      fill="" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10"
      stroke-width="2" stroke-linejoin="round"></path>
  </svg>
);

export default IconSpotify;